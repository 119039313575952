import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import {firebase, auth} from "./api/firebase";

import Buefy from 'buefy'

Vue.use(Buefy)


if (process.env.NODE_ENV != 'production') {
  auth.useEmulator("http://localhost:9099");
}


Vue.config.productionTip = false

// firebase alias for analytics
Vue.prototype.$analytics = firebase.analytics();

// router and firebase integration
firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
      }, reject);
  })
};

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !await firebase.getCurrentUser()){
    next('/sign-in?ref=' + to.fullPath);
  }else{
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
