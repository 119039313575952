import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/polityka-prywatnosci',
    component: () => import(/* webpackChunkName: "Join" */ '../views/Privacy.vue'),
  },
  {
    path: '/join/:id',
    component: () => import(/* webpackChunkName: "Join" */ '../views/Join.vue'),
  },
  {
    path: '/organize',
    component: () => import(/* webpackChunkName: "Create" */ '../views/Organizer/Create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/manage/:id',
    component: () => import(/* webpackChunkName: "Manage" */ '../views/Organizer/Manage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sign-in',
    component: () => import(/* webpackChunkName: "SignIn" */ '../views/SignIn.vue'),
  },
  {
    path: '/join/:drawId/payment/:id',
    component: () => import(/* webpackChunkName: "Payment" */ '../views/Payment.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
    window.scrollTo(0,0);
  }
});

export default router;
