import draw from '../../api/draw';
import router from '../../router';

// initial state
// shape: [{ id, quantity }]

// getters
const getters = {

  //   cartProducts: (state, getters, rootState) => {
  //     return state.items.map(({ id, quantity }) => {
  //       const product = rootState.products.all.find(product => product.id === id)
  //       return {
  //         title: product.title,
  //         price: product.price,
  //         quantity
  //       }
  //     })
  //   },

//   cartTotalPrice: (state, getters) => {
//     return getters.cartProducts.reduce((total, product) => {
//       return total + product.price * product.quantity
//     }, 0)
//   }
};

// actions
const actions = {
    create({ commit, state }, payload) {
      draw.create(payload.draw, state.token).then(value => {
        if (value.errors) {
          commit('setErrors', value.errors);
        } else {
          router.push('/manage/' + value.id)
        }
      })
    },
    start({ commit, state }) {
      draw.start(state.draw.id, state.token).then(value => {
        if (value.errors) {
          commit('setErrors', value.errors);
        } else {
          draw.forOrganizerDetails(state.draw.id, state.token).then((d) => { commit('setDraw', d); commit('setMode', 'view'); })
        }
      });
    },
    update({ commit, state }, payload) {
      const doc = {
        intro: payload.form.intro,
        name: payload.form.name,
        participants: payload.form.participants,
        theme: payload.form.theme,
      };

      commit('setErrors', []);

      draw.update(payload.id, state.token, doc).then(value => {
        if (value.errors) {
          commit('setErrors', value.errors);
        } else {
          draw.forOrganizerDetails(payload.id, state.token).then((d) => { commit('setDraw', d); commit('setMode', 'view'); })

        }
      });
    },
    cancel({ commit }) {
      commit('setErrors', []);
      commit('setMode', 'view');
    },
    edit({ commit }) {
      commit('setMode', 'edit');
    },
    getDraw({ commit, state }, payload) {
      commit('startLoading');
      draw.forOrganizerDetails(payload.id, state.token).then((d) => commit('setDraw', d))
    },
    setErrors({ commit }, errors) {
      commit('setErrors', errors);
    },
    setToken({ commit }, token) {
      commit('setToken', token);
    },

  //   checkout ({ commit, state }, products) {
  //     const savedCartItems = [...state.items]
  //     commit('setCheckoutStatus', null)
  //     // empty cart
  //     commit('setCartItems', { items: [] })
  //     shop.buyProducts(
  //       products,
  //       () => commit('setCheckoutStatus', 'successful'),
  //       () => {
  //         commit('setCheckoutStatus', 'failed')
  //         // rollback to the cart saved before sending the request
  //         commit('setCartItems', { items: savedCartItems })
  //       }
  //     )
  //   },

//   addProductToCart ({ state, commit }, product) {
//     commit('setCheckoutStatus', null)
//     if (product.inventory > 0) {
//       const cartItem = state.items.find(item => item.id === product.id)
//       if (!cartItem) {
//         commit('pushProductToCart', { id: product.id })
//       } else {
//         commit('incrementItemQuantity', cartItem)
//       }
//       // remove 1 item from stock
//       commit('products/decrementProductInventory', { id: product.id }, { root: true })
//     }
//   }
};

// mutations
const mutations = {
  setDraw(state, current) {
    state.loading = false;
    state.draw = current;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setToken(state, token) {
    state.token = token;
  },
  startLoading(state) {
    state.loading = true;
  },
  //   pushProductToCart (state, { id }) {
  //     state.items.push({
  //       id,
  //       quantity: 1
  //     })
  //   },

  //   incrementItemQuantity (state, { id }) {
  //     const cartItem = state.items.find(item => item.id === id)
  //     cartItem.quantity++
  //   },

  //   setCartItems (state, { items }) {
  //     state.items = items
  //   },

//   setCheckoutStatus (state, status) {
//     state.checkoutStatus = status
//   }
};

export default {
  namespaced: true,
  state: {
    token: null,
    draw: null,
    loading: false,
    errors: [],
    mode: 'view'
  },
  getters,
  actions,
  mutations,
};
