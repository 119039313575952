export const organizeCTA = 'https://forms.gle/iTZxVFAVbZWpb7qV9';

const  ads = [
  {id: 'mammarzenie', title: 'Fundacja Mam Marzenie na siepomaga.pl', url: 'https://www.siepomaga.pl/mammarzenie', teaser: '', colorBkg: 'white', colorText: '#27AE61'},
  {id: 'pomorskiehospicjum', title: 'Pomorskie Hospicjum dla Dzieci na siepomaga.pl', url: 'https://www.siepomaga.pl/pomorskiehospicjum', teaser: '', colorBkg: '', colorText: '#0d5098'},
  {id: 'chlebzycia', title: 'Fundacja Domy Wspólnoty Chleb Życia na siepomaga.pl', url: 'https://www.siepomaga.pl/chlebzycia', teaser: '', colorBkg: '', colorText: '#9B6927'}
];

export const getRandomAd = function(i = 0) {
  return ads[i];
};


// <!-- <div class="tile is-child">
//     <a href="https://www.siepomaga.pl/chlebzycia" target="_blank" title="Fundacja Domy Wspólnoty Chleb Życia">
//       <img src="/ad/charity/chlebzycia/logo.jpeg" alt="Fundacja Domy Wspólnoty Chleb Życia" />
//     </a>
// </div> -->
