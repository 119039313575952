import Vue from 'vue';
import Vuex from 'vuex';
import participant from './modules/participant';
import organizer from './modules/organizer';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    participant,
    organizer
  },
});
