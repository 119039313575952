const firebaseConfig = {
  apiKey: 'AIzaSyCKmvCb-Jan_FUxmdKaF-hRATE-JvTWfMs',
  authDomain: 'losowanie-164c3.firebaseapp.com',
  projectId: 'losowanie-164c3',
  storageBucket: 'losowanie-164c3.appspot.com',
  messagingSenderId: '520595714794',
  appId: '1:520595714794:web:9eb3012456dd9bf85b5d93',
  measurementId: 'G-FF6KMQHMF7',
};

export default firebaseConfig;
