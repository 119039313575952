import firebaseConfig from "../../firebase.config";
import _firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

_firebase.initializeApp(firebaseConfig);

export const firebase = _firebase;
export const auth = firebase.auth();

