import draw from "../../api/draw";

// initial state
// shape: [{ id, quantity }]

// getters
const getters = {
  //   cartProducts: (state, getters, rootState) => {
  //     return state.items.map(({ id, quantity }) => {
  //       const product = rootState.products.all.find(product => product.id === id)
  //       return {
  //         title: product.title,
  //         price: product.price,
  //         quantity
  //       }
  //     })
  //   },
  //   cartTotalPrice: (state, getters) => {
  //     return getters.cartProducts.reduce((total, product) => {
  //       return total + product.price * product.quantity
  //     }, 0)
  //   }
};

// actions
const actions = {
  paymentStart({ commit }) {
    commit("paymentStart");
  },
  revealResult({ commit, state }) {
    draw.reveal(state.draw.id, state.current.uuid).then(response => {
      commit("setResult", response.result);
    });
  },
  acceptResult({ commit, state }) {
    draw.accept(state.draw.id, state.current.uuid).then(response => {
      state.result.isAccepted = true;
      state.result.canRedraw = false;
      commit("acceptResult", state.result);
    });
  },
  getDraw({ commit }, payload) {
    commit("startLoading");
    draw.find(payload.id).then(d => commit("setDraw", d));
  },
  setStep({ commit }, step) {
    commit("setStep", step);
  },
  setParticipant({ commit }, participant) {
    commit("setParticipant", participant);
  },
  unsetParticipant({ commit }) {
    commit("unsetParticipant");
  },
  join({ commit }) {
    // @todo business logic for locking
    commit("setStep", "draw");
  }
  //   checkout ({ commit, state }, products) {
  //     const savedCartItems = [...state.items]
  //     commit('setCheckoutStatus', null)
  //     // empty cart
  //     commit('setCartItems', { items: [] })
  //     shop.buyProducts(
  //       products,
  //       () => commit('setCheckoutStatus', 'successful'),
  //       () => {
  //         commit('setCheckoutStatus', 'failed')
  //         // rollback to the cart saved before sending the request
  //         commit('setCartItems', { items: savedCartItems })
  //       }
  //     )
  //   },

  //   addProductToCart ({ state, commit }, product) {
  //     commit('setCheckoutStatus', null)
  //     if (product.inventory > 0) {
  //       const cartItem = state.items.find(item => item.id === product.id)
  //       if (!cartItem) {
  //         commit('pushProductToCart', { id: product.id })
  //       } else {
  //         commit('incrementItemQuantity', cartItem)
  //       }
  //       // remove 1 item from stock
  //       commit('products/decrementProductInventory', { id: product.id }, { root: true })
  //     }
  //   }
};

// mutations
const mutations = {
  paymentStart(state) {
    state.step = "payment";
  },
  async setDraw(state, current) {
    // // draw was loaded

    state.draw = current;

    if (!current) {
      state.loading = false;
      return;
    }

    current.participants.sort((a, b) => a.name.localeCompare(b.name));
    state.draw = current;

    // check if cookie is set
    const participantUuid = getCookie(current.id);
    if (!participantUuid) {
      state.step = "welcome";
      state.loading = false;
      return;
    }

    // find in participants list
    // -- is in participant lists => result was not revealed yet.
    const found = current.participants.find(participant => participant.uuid === participantUuid);
    if (found) {
      state.step = "welcome";
      state.current = found;
      state.loading = false;
    }

    // -- isn't on participant list => result was revelead, load it via API
    else {
      await draw.reveal(current.id, participantUuid).then(result => {
        state.step = "result";
        state.result = result.result;
        state.current = result.participant;
        state.loading = false;
      });
    }
  },
  setResult(state, result) {
    (state.step = "result"), (state.result = result);
  },
  setStep(state, step) {
    state.step = step;
  },
  startLoading(state) {
    state.loading = true;
  },
  setParticipant(state, participant) {
    state.current = participant;
    setCookie(state.draw.id, participant.uuid, 90);
  },
  unsetParticipant(state) {
    state.current = undefined;
    setCookie(state.draw.id, "unset", -90);
  },
  acceptResult(state, result) {
    state.step = "result";
    state.result = result;
  }
  //   pushProductToCart (state, { id }) {
  //     state.items.push({
  //       id,
  //       quantity: 1
  //     })
  //   },

  //   incrementItemQuantity (state, { id }) {
  //     const cartItem = state.items.find(item => item.id === id)
  //     cartItem.quantity++
  //   },

  //   setCartItems (state, { items }) {
  //     state.items = items
  //   },

  //   setCheckoutStatus (state, status) {
  //     state.checkoutStatus = status
  //   }
};

export default {
  namespaced: true,
  state: {
    loading: null,
    draw: null,
    current: null,
    selected: null,
    step: null,
    result: null
  },
  getters,
  actions,
  mutations
};

/** @deprecated use api/Participant */
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/** @deprecated use api/Participant */
function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
