let baseUrl = 'http://localhost:5001/losowanie-164c3/europe-west3/app';
if (process.env.NODE_ENV == 'production') {
  baseUrl = 'https://europe-west3-losowanie-164c3.cloudfunctions.net/app';
}

const getHeaders = function(token) {
  const headers = {
    'Content-Type': 'application/json'
  }

  if (token) {
    headers['Authorization'] = token;
  }

  return headers;
}

export const api = {
  get: function(url, params = {}, token = null) {
    params['headers'] = getHeaders(token);
    return this.call('GET', url, params);
  },
  post: function(url, json, params = {}, token = null) {
    params['headers'] = getHeaders(token);
    params['body'] = JSON.stringify(json);
    return this.call('POST', url, params);
  },

  put: function(url, json, params = {}, token = null) {
    params['headers'] = getHeaders(token);
    params['body'] = JSON.stringify(json);
    return this.call('PUT', url, params);
  },


  call: function(method, url, params) {
    params['method'] = method;
    return fetch(baseUrl + '/' + url, params).then(res => {
      if (res.status != 201 && res.status != 200) {
        // fix this..
        console.error('request error = ' + res.status)
      }

      if (res.body) {
        return res.json();
      } else return "";

    }).catch(err => {
      // fix me
      return err;
    });
  }
}
