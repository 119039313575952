<template>
  <div class="tile is-child ad" v-if="ad">
      <a :style="style" :href="ad.url" target="_blank" :title="ad.title" class="is-size-5">
        {{ ad.title }}
      </a>
  </div>
</template>

<script>
export default {
  props: ['ad'],
  computed: {
    style() {
      return { color: this.ad.colorText }
    }
  }
}
</script>

<style>
  .ad a { font-weight: bold; padding: 1em; max-width: 320px; display: block; text-align: center; margin: 0 auto; }
</style>
