import { api } from './base';

export default {
  // participant
  find(id) { return api.get(id) },
  reveal(id, uuid) { return api.get(id + '/result/' + uuid) },
  accept(id, uuid) { return api.post(id + '/result/' + uuid, {}) },
  storeWishlist(id, uuid, wishlist) { return api.post(id + '/result/' + uuid + '/wishlist', { wishlist: wishlist }, {}, uuid) },

  // organizer
  forOrganizerDetails(id, token) { return api.get(id, {}, token); },
  create(draw, token) { return api.post('create', draw, {}, token); },
  update(id, token, draw) { return api.put(id, draw, {}, token); },
  start(id, token) { return api.post(id + '/start', {}, {}, token); },
};
