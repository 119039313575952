<template>
  <div>
    <section class="hero is-fullheight">
      <img src="bkg.jpg" class="is-transparent hero-background" />
      <div class="hero-body">
        <div class="container">
          <div class="columns is-vcentered">
            <div
              class="
                column
                is-10-mobile
                is-10-tablet
                is-5-desktop
                is-5-widescreen
                is-5-fullhd
                content
              "
            >
              <!-- <img class="image is-hidden-desktop is-128x128 mb-3" src="../../public/icons/gift-box.svg" /> -->
              <p class="font-cookie mb-0 is-size-4">swiatecznelosowanie.pl</p>
              <h1 class="is-size-1 mt-0 mb-6">
                Prezent za prezent
              </h1>
              <div class="is-size-5">
                <p>Słyszeliscie juz o świątecznym losowaniu?</p>
                <ul class="list">
                  <li class="list-item">zbierz rodzine lub znajomych</li>
                  <li class="list-item">wymieniaj prezenty anonimowo</li>
                  <li class="list-item">... i ciesz się z niespodzianek!</li>
                </ul>
              </div>
              <div class="buttons mt-6">
                <a class="button is-primary is-size-6" :href="cta">
                  Stwórz własne losowanie
                </a>
                <a class="button is-size-6" href="#how">
                  Jak to działa?
                </a>
              </div>
            </div>
            <div
              class="
                column
                is-hidden-mobile
                is-10-mobile
                is-10-tablet
                is-4-desktop
                is-7-widescreen
                is-4-fullhd
                is-offset-1-fullhd
              "
            >
              <!-- <figure class="image is-square">
                <img class="white-svg" src="../../public/icons/gift-box.svg" />
              </figure> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="how" class="hero is-white " v-for="(benefit, i) in benefits" :key="i">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-vcentered">
            <div
              v-if="benefit.type == 'left'"
              class="
                column
                is-10-mobile
                is-offset-1-mobile
                is-10-tablet
                is-offset-1-tablet
                is-4-desktop
                is-offset-1-desktop
                is-4-widescreen
                is-offset-1-widescreen
                is-4-fullhd
                is-offset-1-fullhd
              "
              data-aos="fade-right"
            >
              <figure class="image is-square">
                <img :src="benefit.img" />
              </figure>
            </div>
            <div
              class="
                column
                is-10-mobile
                is-offset-1-mobile
                is-10-tablet
                is-offset-1-tablet
                is-5-desktop
                is-offset-1-desktop
                is-5-widescreen
                is-offset-1-widescreen
                is-5-fullhd
                is-offset-1-fullhd
              "
              data-aos="fade-left"
            >
              <h1 v-if="i === 0" class="title titled is-4">Jak to działa?</h1>

              <h1 class="titled title is-2 mb-6">
                {{ benefit.title }}
              </h1>
              <div class=" content is-size-5">
                {{ benefit.content }}
              </div>
            </div>
            <div
              v-if="benefit.type == 'right'"
              class="
                column
                is-10-mobile
                is-offset-1-mobile
                is-10-tablet
                is-offset-1-tablet
                is-4-desktop
                is-offset-1-desktop
                is-4-widescreen
                is-offset-1-widescreen
                is-4-fullhd
                is-offset-1-fullhd
              "
            >
              <figure class="image is-square">
                <img :src="benefit.img" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="hero is-medium has-text-centered">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-8">
              <h1 class="title titled is-1 mb-6">
                Primary bold title <span id="typewriter"></span>
              </h1>
              <h2 class="subtitle subtitled">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Laborum cupiditate dolorum vitae dolores nesciunt totam magni
                quas. Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="hero is-primary has-text-centered mt-4 mb-4 bottom-cta">
      <div class="hero-body">
        <p class="title">
          Zaczynamy?
        </p>
        <p class="content is-size-5">
          <strong>Spraw, by te święta były pełne tajemnicy i niespodzianek!</strong>
        </p>
        <p class="subtitle mt-4">
          <a :href="cta" class="button">Stwórz własne losowanie</a>
        </p>
      </div>
    </section>

    <section class="section">
      <div class="container has-text-centered">
        <h2 class="title is-2">Dla kogo?</h2>
        <!-- <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p> -->

        <div class="columns is-centered" style="padding: 2rem">
          <div class="column" v-for="(target, i) in targets" :key="i">
            <div class="card">
              <div class="card-image">
                <figure class="image is-2by1">
                  <img :src="target.img" :alt="target.title" />
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{ target.title }}</p>
                  </div>
                </div>

                <div class="content">
                  {{ target.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <p class="has-text-centered">Zaczynamy? <a :href="cta">Stwórz własne losowanie</a></p>
      </div>
    </section>

    <section
      class="hero has-text-centered mt-4 mb-2 has-background-white"
      style="border-top: 3px solid #fafafa"
    >
      <div class="hero-body">
        <h2 class="title is-2">
          Nie zapominaj o innych!
        </h2>

        <div class="tile is-ancestor">
          <div class="tile" />
          <AdTile :ad="ads[0]" />
          <AdTile :ad="ads[1]" />
          <AdTile :ad="ads[2]" />
          <div class="tile" />
        </div>
      </div>
    </section>

    <footer class="footer has-background">
      <div class="content has-text-centered">
        <p>
          <a :href="cta">Stwórz własne losowanie</a>
          | <a href="/polityka-prywatnosci" target="_blank">Polityka prywatności</a> |
          <a href="https://www.vecteezy.com/free-vector/christmas">Christmas Vectors by Vecteezy</a>
        </p>
        <p>swiatecznelosowanie.pl &copy;2022-2023</p>
      </div>
    </footer>
  </div>
</template>

<script>
import { organizeCTA, getRandomAd } from "@/config.js";
import AdTile from "@/components/Home/AdTile";
export default {
  name: "Home",
  mounted() {
    AOS.init();
  },
  components: { AdTile },
  data() {
    return {
      cta: organizeCTA,
      ads: [getRandomAd(0), getRandomAd(1), getRandomAd(2)],
      targets: [
        {
          title: "W rodzinine",
          content:
            "Magia świąt Bożego Narodzenia i wspaniały sposób na wzmocnienie więzi rodzinnych. Dodatkowy bonus to kontrola kosztów prezentów i spokojniejsze święta. 🎅🎁 #ŚwiętaWRodzinie",
          img: "/img/family.jpg"
        },
        {
          title: "Wśród znajomych",
          content:
            "To świetna okazja do niespodzianek i jeszcze lepszego spędzenia czasu razem! Kto jest gotowy na trochę świątecznej magii? 🎅🎁 #ZabawaŚwiąteczna",
          img: "/img/friends.jpg"
        },
        {
          title: "W pracy",
          content:
            "To nie tylko prezenty, ale tez okazja do integracji zespołu. Rozpocznijmy wspólnie sezon świąteczny pełen radości i niespodzianek! 🎁❄️ #ŚwiętaWPracy",
          img: "/img/company.jpg"
        }
      ],
      benefits: [
        {
          title: "Zaproś uczestników",
          content:
            "Pierwszym krokiem jest wybranie osób, które wezmą udział w zabawie. Osoba biorąca udział w zabawie losuje imię innej osoby, dla której przygotuje prezent. Ważne jest zachowanie tajemnicy. Ustalcie budzet i przygotujcie prezenty na wybrany dzień!",
          img: "/img/home-1.jpeg",
          type: "left"
        },
        {
          title: "Spersonalizuj losowanie",
          content:
            "Mozesz wybrać jeden z kilku animowanych motywów losowania. Stwórz wyjątkowy i magiczny świąteczny klimat, a następnie wyślij link by rozpocząć losownie!",
          img: "/img/home-2.jpg",
          type: "right"
        },
        {
          title: "Chcesz zmienić wynik?",
          content:
            "Nie ma problemu! Umozliwimy Ci ponowne losowanie. Mozesz to zrobić tylko jeden raz. Nie musisz się jednak martwić. Kazdy uczestnik moze podpowiedziec podpowiadać innym o czym marzy!",
          // "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laborum cupiditate dolorum vitae dolores nesciunt totam magni quas. Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
          img: "/img/home-3.jpg",
          type: "left"
        }
      ]
    };
  }
};
</script>

<style lang="sass">
.hero
  background-color: #c8beb9;
  &.has-background
    position: relative
    overflow: hidden
  &-background
    position: absolute
    object-fit: cover
    object-position: center center
    width: 100%
    height: 100%
    &.is-transparent
      opacity: 0.6


.bottom-cta
  background-image: url("/img/bkg.jpg")
  background-repeat: repeat
</style>
